import React from "react";
import { gql, useLazyQuery } from "@apollo/client";

import { isNotNil } from "./utils/guards";
import { Viewer } from "./types/api";

/**
 * Types
 */

type AuthContextType = {
  viewer: Viewer | null;
  isLoggedIn: () => boolean;
  signIn: (userToken: string) => Promise<any>;
  signOut: () => Promise<any>;
};

interface ViewerData {
  me: Viewer;
}

/**
 * GQL
 */

const VIEWER_QUERY = gql`
  query getViewer {
    me {
      email
      firstName
      lastName
      photo
      currentFlatfileTeamId
      teams {
        id
        name
      }
    }
  }
`;

/**
 * Main
 */

const AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [viewer, setViewer] = React.useState<Viewer | null>(null);
  const [fetchViewer, { client }] = useLazyQuery<ViewerData, {}>(VIEWER_QUERY);

  React.useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchViewer();

      if (isNotNil(data)) {
        setViewer(data.me);
      }
    };

    fetchData();
  }, [fetchViewer]);

  const isLoggedIn = (): boolean => {
    return isNotNil(localStorage.getItem("token"));
  };

  const signIn = async (userToken: string) => {
    localStorage.setItem("token", userToken);

    const { data } = await fetchViewer();

    if (isNotNil(data)) {
      setViewer(data.me);
    }
  };

  const signOut = async (): Promise<void> => {
    localStorage.removeItem("token");

    setViewer(null);
    await client.clearStore();
  };

  return (
    <AuthContext.Provider value={{ viewer, isLoggedIn, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  return React.useContext(AuthContext);
};
