/**
 * Types
 */

export interface ErrorProps {
  readonly message?: string;
  readonly title?: string;
}

/**
 * Main
 */

export const Error = (props: ErrorProps): JSX.Element => {
  const { message = "Well this is embarrassing...", title = "Oops!" } = props;

  return (
    <div className="">
      <div className="">
        <h1>{title}</h1>
        <span>{message}</span>
      </div>
    </div>
  );
};
