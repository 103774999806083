import React from "react";
import { NavLink } from "react-router-dom";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  HomeIcon,
  MenuIcon,
  UserGroupIcon,
  XIcon,
  ShoppingBagIcon,
  BeakerIcon,
} from "@heroicons/react/outline";
import { SelectorIcon } from "@heroicons/react/solid";
import { match } from "ts-pattern";

import "./App.scss";
import { Logout } from "./components/Logout";
import { isNotNil } from "./utils/guards";
import { useAuth } from "./AuthContext";

/**
 * Types
 */

type IconVariation = "coconuts" | "contacts" | "home" | "products" | "submit";

type IconProps = {
  isActive: boolean;
  name: IconVariation;
};

/**
 * Main
 */

const Icon = (props: IconProps): JSX.Element => {
  const { isActive, name } = props;

  const getIcon = () => {
    return match(name)
      .with("coconuts", () => BeakerIcon)
      .with("contacts", () => UserGroupIcon)
      .with("home", () => HomeIcon)
      .with("products", () => ShoppingBagIcon)
      .with("submit", () => BeakerIcon)
      .exhaustive();
  };

  const HeroIcon = getIcon();

  return (
    <HeroIcon
      className={classNames(
        isActive ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
        "mr-4 h-6 w-6",
      )}
      aria-hidden="true"
    />
  );
};

const navLinks: ReadonlyArray<{ label: string; to: string; heroIcon: IconVariation }> = [
  { label: "Home", to: "/", heroIcon: "home" },
  { label: "CRM Contacts", to: "/contacts", heroIcon: "contacts" },
  { label: "Products", to: "/products", heroIcon: "products" },
];

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Layout = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false);
  const [accessKeyId, setAccessKeyId] = React.useState<string>("");
  const [secretAccessKey, setSecretAccessKey] = React.useState<string>("");
  const [teamId, setTeamId] = React.useState<string>("");
  const { viewer } = useAuth();

  const closeModal = (): void => {
    setIsSidebarOpen(false);
  };

  return (
    <React.Fragment>
      <div className="h-full flex">
        <Transition.Root show={isSidebarOpen} as={React.Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={closeModal}
            open={isSidebarOpen}
          >
            <Transition.Child
              as={React.Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={React.Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img className="h-8 w-auto" src="/logo-big.png" alt="Workflow" />
                  </div>
                  <nav aria-label="Sidebar" className="mt-5">
                    <div className="px-2 space-y-1">
                      {navLinks.map((item) => (
                        <NavLink
                          key={item.label}
                          to={item.to}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                            )
                          }
                        >
                          {({ isActive }) => (
                            <React.Fragment>
                              <Icon isActive={isActive} name={item.heroIcon} />
                              {item.label}
                            </React.Fragment>
                          )}
                        </NavLink>
                      ))}
                    </div>
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                  <Menu as="div" className="px-3 relative text-left">
                    <div>
                      <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                        <span className="flex w-full justify-between items-center">
                          <span className="flex min-w-0 items-center justify-between space-x-3">
                            <img
                              className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                              src={isNotNil(viewer) ? viewer.photo : "/nick.jpg"}
                              alt=""
                            />
                            <span className="flex-1 flex flex-col min-w-0">
                              <span className="text-gray-900 text-sm font-medium truncate">
                                {isNotNil(viewer) ? viewer.firstName : ""}{" "}
                                {isNotNil(viewer) ? viewer.lastName : ""}
                              </span>
                              <span className="text-gray-500 text-sm truncate">
                                {isNotNil(viewer) ? viewer.email : ""}
                              </span>
                            </span>
                          </span>
                          <SelectorIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 bottom-100% mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none bottom-menu-100">
                        <div className="py-1">
                          <div className="px-3 mt-5">
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                              <label
                                htmlFor="access_key_id"
                                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                              >
                                ACCESS KEY ID
                              </label>
                              <input
                                autoComplete="off"
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm outline-none"
                                id="access_key_id"
                                name="access_key_id"
                                placeholder=""
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="px-3 mt-5">
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                              <label
                                htmlFor="secret_access_key"
                                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                              >
                                SECRET ACCESS KEY
                              </label>
                              <input
                                autoComplete="off"
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm outline-none"
                                id="secret_access_key"
                                name="secret_access_key"
                                placeholder=""
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="px-3 my-5">
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                              <label
                                htmlFor="team_id"
                                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                              >
                                TEAM ID
                              </label>
                              <input
                                autoComplete="off"
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm outline-none"
                                id="team_id"
                                name="team_id"
                                placeholder="1234"
                                type="text"
                                value={viewer?.currentFlatfileTeamId || ""}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="py-1">
                          <Logout />
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64">
            <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-gray-100">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                  <img className="h-8 w-auto" src="/logo-big.png" alt="Coconut Logo" />
                </div>
                <nav className="mt-5 flex-1" aria-label="Sidebar">
                  <div className="px-2 space-y-1">
                    {navLinks.map((item) => (
                      <NavLink
                        key={item.label}
                        to={item.to}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-gray-200 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
                          )
                        }
                      >
                        {({ isActive }) => (
                          <React.Fragment>
                            <Icon isActive={isActive} name={item.heroIcon} />
                            {item.label}
                          </React.Fragment>
                        )}
                      </NavLink>
                    ))}
                  </div>
                </nav>
                <div className="px-2 space-y-1">
                  <Menu as="div" className="relative text-left">
                    <div>
                      <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                        <span className="flex w-full justify-between items-center">
                          <span className="flex min-w-0 items-center justify-between space-x-3">
                            <img
                              className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                              src={isNotNil(viewer) ? viewer.photo : "/nick.jpg"}
                              alt=""
                            />
                            <span className="flex-1 flex flex-col min-w-0">
                              <span className="text-gray-900 text-sm font-medium truncate">
                                {isNotNil(viewer) ? viewer.firstName : ""}{" "}
                                {isNotNil(viewer) ? viewer.lastName : ""}
                              </span>
                              <span className="text-gray-500 text-sm truncate">
                                {isNotNil(viewer) ? viewer.email : ""}
                              </span>
                            </span>
                          </span>
                          <SelectorIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 bottom-100% mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none bottom-menu-100">
                        <div className="py-1">
                          <div className="px-3 mt-5">
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                              <label
                                htmlFor="name"
                                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                              >
                                ACCESS KEY ID
                              </label>
                              <input
                                autoComplete="off"
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm outline-none"
                                id="name"
                                name="name"
                                onChange={(evt) => setAccessKeyId(evt.target.value)}
                                placeholder=""
                                type="text"
                                value={accessKeyId}
                              />
                            </div>
                          </div>
                          <div className="px-3 mt-5">
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                              <label
                                htmlFor="name"
                                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                              >
                                SECRET ACCESS KEY
                              </label>
                              <input
                                autoComplete="off"
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm outline-none"
                                id="name"
                                name="name"
                                onChange={(evt) => setSecretAccessKey(evt.target.value)}
                                placeholder=""
                                type="text"
                                value={secretAccessKey}
                              />
                            </div>
                          </div>
                          <div className="px-3 my-5">
                            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                              <label
                                htmlFor="name"
                                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                              >
                                TEAM ID
                              </label>
                              <input
                                autoComplete="off"
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm outline-none"
                                id="name"
                                name="name"
                                onChange={(evt) => setTeamId(evt.target.value)}
                                placeholder="1234"
                                type="text"
                                value={viewer?.currentFlatfileTeamId || teamId}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="py-1">
                          <Logout />
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="lg:hidden">
            <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
              <div>
                <img className="h-8 w-auto" src="/logo-mark.png" alt="Coconut Logo" />
              </div>
              <div>
                <button
                  type="button"
                  className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                  onClick={() => setIsSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
              <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                <div className="h-full rounded-lg">{children}</div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
