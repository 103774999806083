import { isUndefined } from "../utils/guards";

/**
 * Types
 */

type Fn = (msg: string, val?: unknown) => void;

type Logger = Record<"error" | "info" | "warn", Fn>;

/**
 * Main
 */

export const logger: Logger = {
  error: (msg, val) => {
    if (process.env.NODE_ENV !== "production") {
      if (isUndefined(val)) {
        console.error(msg);
      } else {
        console.error(msg, val);
      }
    }
  },
  info: (msg, val) => {
    if (process.env.NODE_ENV !== "production") {
      if (isUndefined(val)) {
        console.info(msg);
      } else {
        console.info(msg, val);
      }
    }
  },
  warn: (msg, val) => {
    if (process.env.NODE_ENV !== "production") {
      if (isUndefined(val)) {
        console.warn(msg);
      } else {
        console.warn(msg, val);
      }
    }
  },
};
