import React from "react";
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { useLocation, useNavigate } from "react-router-dom";

import { isNil } from "../utils/guards";
import { logger } from "../utils/logger";
import { useAuth } from "../AuthContext";

/**
 * Types
 */

const isGoogleLoginResponse = (res: unknown): res is GoogleLoginResponse => {
  return Object.prototype.hasOwnProperty.call(res, "tokenId");
};

type RRState = {
  state: {
    from: any;
  };
};

const isRRState = (val: unknown): val is RRState => {
  return Object.prototype.hasOwnProperty.call(val, "state");
};

/**
 * Main
 */

const GOOGLE_CLIENT_ID: string =
  "270449751-4q7k620099pr7sa2gur1tbeb2ftagl14.apps.googleusercontent.com";

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.isLoggedIn()) {
      if (isRRState(location)) {
        const from: string = location.state?.from?.pathname || "/";

        return navigate(from, { replace: true });
      }

      return navigate("/", { replace: true });
    }
  }, [auth, location, navigate]);

  const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if (isGoogleLoginResponse(res)) {
      const profile = res.profileObj;

      const response = await fetch("/api/auth/google", {
        method: "POST",
        body: JSON.stringify({
          token: res.tokenId,
          photo: profile.imageUrl,
          firstName: profile.givenName,
          lastName: profile.familyName,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const { token } = await response.json();

      if (isNil(token)) {
        logger.error("Something went wrong with Oauth");
        return;
      }

      await auth.signIn(token);
    }
  };

  const onFailure = (err: unknown): void => {
    logger.error("Login failed: res:", { err });
  };

  return (
    <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
      <div className="text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block text-indigo-600 xl:inline">Flatfile Demo App</span>
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Click login to get started
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <GoogleLogin
              buttonText="Login"
              clientId={GOOGLE_CLIENT_ID}
              cookiePolicy="single_host_origin"
              isSignedIn={true}
              onFailure={onFailure}
              onSuccess={onSuccess}
              render={(props) => (
                <button
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  disabled={props.disabled}
                  onClick={props.onClick}
                >
                  Login
                </button>
              )}
              style={{ marginTop: "100px" }}
              uxMode="redirect"
            />
          </div>
        </div>
      </div>
    </main>
  );
};
