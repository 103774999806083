import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";

import { ContactsQ3 } from "./pages/ContactsQ3";
import { Products } from "./pages/Products";
import { Home } from "./pages/Home";
import { Layout } from "./Layout";
import { Login } from "./components/Login";
import { isFalse } from "./utils/guards";
import { AuthProvider, useAuth } from "./AuthContext";

/**
 * Main
 */

const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  if (isFalse(isLoggedIn())) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export const App = (): JSX.Element => {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/contacts"
              element={
                <RequireAuth>
                  <ContactsQ3 />
                </RequireAuth>
              }
            />
            <Route
              path="/products"
              element={
                <RequireAuth>
                  <Products />
                </RequireAuth>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
};
