import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import "../App.scss";
import { isNil } from "../utils/guards";
import { logger } from "../utils/logger";
import { Embed, DataContactInput, Contact } from "../types/api";
import { Flatfile } from "@flatfile/sdk";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";

/**
 * Types
 */

interface EmbedData {
  getAllEmbeds: Array<Embed>;
}

interface ContactsData {
  getContacts: Array<Contact>;
}

/**
 * GQL
 */

const EMBEDS_QUERY = gql`
  query GetAllEmbeds {
    getAllEmbeds {
      flatfileEmbedId
      jwt
    }
  }
`;

const CONTACTS_DEMO_QUERY = gql`
  query GetAllContacts {
    getContacts {
      id
      dateCreated
      firstName
      lastName
      email
      phone
      addressLine1
      city
      state
      zipCode
      country
      optIn
      dealStatus
      createdAt
      updatedAt
    }
  }
`;

const ADD_CONTACTS_MUTATION = gql`
  mutation InsertContacts($contacts: [DataContactInput!]!) {
    insertContacts(contacts: $contacts) {
      id
      dateCreated
      firstName
      lastName
      email
      phone
      addressLine1
      city
      state
      zipCode
      country
      optIn
      dealStatus
    }
  }
`;

const DELETE_ALL_CONTACTS_MUTATION = gql`
  mutation DeleteAllContacts($contacts: [Int!]!) {
    deleteContacts(contacts: $contacts) {
      id
      dateCreated
      firstName
      lastName
      email
      phone
      addressLine1
      city
      state
      zipCode
      country
      optIn
      dealStatus
      createdAt
      updatedAt
    }
  }
`;

interface ContactsVars {
  contacts: Array<DataContactInput>;
}

interface ContactIdVars {
  contacts: Array<string>;
}

/**
 * Main
 */

const EMBED_ID: string = "a136fa38-ef68-442e-9da7-6f2cedb2c8bb";

const getEmbedToken = (embeds: Array<Embed>): string | null => {
  const found = embeds.find((embed: Embed) => embed.flatfileEmbedId === EMBED_ID);

  return isNil(found) ? null : found.jwt;
};

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ContactsQ3 = (): JSX.Element => {
  const { data: embeds } = useQuery<EmbedData, {}>(EMBEDS_QUERY);
  const { data: contacts, refetch } = useQuery<ContactsData, {}>(CONTACTS_DEMO_QUERY, {
    fetchPolicy: "network-only",
  });
  const [addContacts] = useMutation<{}, ContactsVars>(ADD_CONTACTS_MUTATION);
  const [deleteContacts] = useMutation<{}, ContactIdVars>(DELETE_ALL_CONTACTS_MUTATION);

  const openFlatfile = () => {
    const jwt = getEmbedToken(embeds?.getAllEmbeds ?? []);

    Flatfile.requestDataFromUser({
      token: jwt ?? "",
      embedId: EMBED_ID,
      theme: {
        logo: "https://coconut.show/logo-mark.png",
        hideConfetti: false,
        // loadingText: 'Custom loading text ...',
        // submitCompleteText: 'Custom submit text ...',
        displayName: "Coconut CRM",
      },
      onInit({ session }) {
        // Meta contains: {
        //   batchId, workspaceId, workbookId, schemaIds, mountUrl
        // }

        // example usage:
        session.updateEnvironment({ dateFormat: { selected }["selected"]["value"] });
        console.log({ selected }["selected"]["value"]);
      },
      onData(chunk, next) {
        const contactData = chunk.records.map((record) => {
          const { data } = record;
          return {
            dateCreated: data?.dateCreated === "" ? null : data.dateCreated,
            firstName: data?.firstName === "" ? null : data.firstName,
            lastName: data?.lastName === "" ? null : data.lastName,
            email: data?.email === "" ? null : data.email,
            phone: data?.phone === "" ? null : data.phone,
            addressLine1: data?.addressLine1 === "" ? null : data.addressLine1,
            city: data?.city === "" ? null : data.city,
            state: data?.state === "" ? null : data.state,
            zipCode: data?.zipCode === "" ? null : data.zipCode,
            country: data?.country === "" ? null : data.country,
            postalCode: data?.postalCode === "" ? null : data.postalCode,
            optIn: data?.optIn === "" ? null : data.optIn,
            dealStatus: data?.dealStatus === "" ? null : data.dealStatus,
          };
        });

        addContacts({
          variables: { contacts: contactData as Array<DataContactInput> },
        }).catch((err) => logger.error("Failed to insert contacts: ", err));

        next();
      },
      onComplete() {
        refetch().catch((err) => logger.error("Failed to refetch contacts", err));
      },
      onError: ({ error }) => {
        console.log(`[${error.code}] ${error.userMessage}`);
      },
    });
  };

  const dateFormats = [
    {
      id: 1,
      label: "YYYY-MM-DD",
      value: "yyyy-MM-dd",
    },
    {
      id: 2,
      label: "MM/DD/YYYY",
      value: "MM/dd/yyyy",
    },
    {
      id: 3,
      label: "Month DD, YYYY",
      value: "MMMM dd, yyyy",
    },
  ];

  const [selected, setSelected] = React.useState<any>(dateFormats[0]);
  const [checked, setChecked] = React.useState(false);
  const [selectedPeople, setSelectedPeople] = React.useState<Array<Contact>>([]);

  const toggleAll = () => {
    setSelectedPeople(checked ? [] : contacts?.getContacts ?? []);
    setChecked(!checked);
  };

  return (
    <React.Fragment>
      <div className="pb-2">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              CRM Contacts
            </h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            <a
              className="text-indigo-600 hover:text-indigo-900"
              href="https://docs.google.com/spreadsheets/d/1Gr3mmSkNh9RpQwr7wjpNztdYE9jlIO1X_EQpNUk9i8w/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: "38px" }}
            >
              Example File
            </a>
            <button
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-coconut-600 hover:bg-coconut-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={openFlatfile}
            >
              Import Contacts
            </button>
          </div>
        </div>
      </div>

      <div className="md:flex md:items-center md:justify-end">
        <div className="mt-4 flex md:mt-0">
          <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
              <>
                <Listbox.Label style={{ lineHeight: "38px" }}>Date Format</Listbox.Label>
                <div className="ml-3 relative">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-9 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center">
                      <span className="ml-2 block truncate">{selected.label}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {dateFormats.map((format) => (
                        <Listbox.Option
                          key={format.id}
                          className={({ active }) =>
                            classNames(
                              active ? "text-white bg-indigo-600" : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-3 pr-9",
                            )
                          }
                          value={format}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "ml-3 block truncate",
                                  )}
                                >
                                  {format.label}
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4",
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedPeople.length > 0 && (
                <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                  <button
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                    onClick={async () => {
                      const ids = selectedPeople.map(({ id }) => id);
                      await deleteContacts({ variables: { contacts: ids } })
                        .then(() => {
                          setChecked(false);
                          setSelectedPeople([]);
                        })
                        .catch((err) => logger.error("Failed to delete contacts: ", err));
                      await refetch();
                    }}
                  >
                    Delete all
                  </button>
                </div>
              )}
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      City
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      State
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Deal Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {contacts &&
                    contacts.getContacts.map((person) => (
                      <tr
                        key={person.id}
                        className={selectedPeople.includes(person) ? "bg-gray-50" : undefined}
                      >
                        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                          {selectedPeople.includes(person) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                            value={person.id}
                            checked={selectedPeople.find((p) => p.id === person.id) ? true : false}
                            onChange={(e) => {
                              if (selectedPeople.find((p) => p.id === person.id) === undefined) {
                                setSelectedPeople([...selectedPeople, person]);
                              } else {
                                setSelectedPeople(selectedPeople.filter((p) => p.id !== person.id));
                              }
                            }}
                          />
                        </td>
                        <td
                          className={classNames(
                            "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                            selectedPeople.includes(person) ? "text-indigo-600" : "text-gray-900",
                          )}
                        >
                          {person.firstName} {person.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.city}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.state}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.dealStatus}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
