import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { gql, useMutation, useQuery } from "@apollo/client";

import "../App.scss";
import { isNil } from "../utils/guards";
import { logger } from "../utils/logger";
import { BatchData, DataProductInput, Embed, Product } from "../types/api";

/**
 * Types
 */

interface EmbedsData {
  getAllEmbeds: Array<Embed>;
}

interface ProductsData {
  getProducts: Array<Product>;
}

type SDKResponse = {
  rows: Array<{
    id: number;
    status: string;
    valid: boolean;
    data: unknown;
    info: Array<string>;
  }>;
  totalRows: number;
};

const isDataProductInput = (val: unknown): val is DataProductInput => {
  return Object.prototype.hasOwnProperty.call(val, "sku");
};

const isSDKResponse = (val: unknown): val is SDKResponse => {
  if (typeof val === "object") {
    return Object.prototype.hasOwnProperty.call(val, "rows");
  }

  return false;
};

/**
 * GQL
 */

const EMBEDS_QUERY = gql`
  query GetAllEmbeds {
    getAllEmbeds {
      flatfileEmbedId
      jwt
    }
  }
`;

const PRODUCTS_DEMO_QUERY = gql`
  query GetAllProducts {
    getProducts {
      id
      sku
      name
      description
      size
      color
      featured
      image
      currency
      price
      cost
      availableAfter
      availableBefore
      createdAt
      updatedAt
    }
  }
`;

const ADD_PRODUCTS_MUTATION = gql`
  mutation InsertProducts($products: [DataProductInput!]!) {
    insertProducts(products: $products) {
      id
      sku
      name
      description
      size
      color
      featured
      image
      currency
      price
      cost
      availableAfter
      availableBefore
      createdAt
      updatedAt
    }
  }
`;

interface ProductsVars {
  products: Array<DataProductInput>;
}

/**
 * Main
 */

const flatfileImporter = (window as any).flatfileImporter;

const EMBED_ID: string = "bafedf6e-0133-4155-bcbe-e4d017aaf8ec";

const getEmbedToken = (embeds: Array<Embed>): string | null => {
  const found = embeds.find((embed: Embed) => embed.flatfileEmbedId === EMBED_ID);

  return isNil(found) ? null : found.jwt;
};

export const EmptyState = (): JSX.Element => {
  return <div>No Products</div>;
};

export const Products = (): JSX.Element => {
  const importerRef = React.useRef<any>();
  const { data: embeds } = useQuery<EmbedsData, {}>(EMBEDS_QUERY);
  const { data: products, refetch } = useQuery<ProductsData, {}>(PRODUCTS_DEMO_QUERY, {
    fetchPolicy: "network-only",
  });
  const [addProducts] = useMutation<{}, ProductsVars>(ADD_PRODUCTS_MUTATION);

  React.useEffect(() => {
    const embedToken = getEmbedToken(embeds?.getAllEmbeds ?? []);

    if (embedToken) {
      const importer = flatfileImporter(embedToken);

      importer.on("init", ({ batchId }: { batchId: string }): void => {
        logger.info(`Batch ${batchId} has been initialized.`);
      });

      importer.on("launch", ({ batchId }: { batchId: string }): void => {
        logger.info(`Batch ${batchId} has been launched.`);
      });

      importer.on("error", (error: unknown): void => {
        logger.error("Error: ", error);
      });

      importer.on("complete", async (payload: BatchData): Promise<void> => {
        const batchData = await payload.data();

        if (isSDKResponse(batchData)) {
          const decoded = batchData.rows
            .filter((row) => row.valid)
            .filter((row) => isDataProductInput(row.data))
            .map((row) => row.data);

          addProducts({ variables: { products: decoded as Array<DataProductInput> } }).catch(
            (err) => {
              logger.error("Failed to insert products: ", err);
            },
          );

          refetch().catch((err) => {
            logger.error("Failed to refetch products", err);
          });
        }
      });

      importerRef.current = importer;
    }

    return () => {};
  }, [addProducts, embeds, refetch]);

  const openFlatfile = async (): Promise<void> => {
    try {
      await importerRef.current.launch();
    } catch (err) {
      logger.error("Failed to open importer", err);
    }
  };

  return (
    <React.Fragment>
      <div className="pb-8">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Products
            </h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            <a
              href="https://docs.google.com/spreadsheets/d/1y-P9wDaypDcmZuacSAAuBsSwpwJAQOqu3lUDiFtjHD4/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              style={{ lineHeight: "38px" }}
            >
              Example File
            </a>
            <button
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={openFlatfile}
            >
              Import Products
            </button>
          </div>
        </div>
      </div>

      {products && products.getProducts.length > 0 ? (
        <React.Fragment>
          <div className="max-w-2xl mx-auto sm:py-2 sm:px-6 lg:max-w-7xl lg:px-8">
            {" "}
            <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
              {products?.getProducts.slice(0, 8).map((product, index) => (
                <div key={product.sku} className="group relative">
                  <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                    <img
                      alt={product.name}
                      className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                      src={`/product-images/product-image-00${((index + 9) % 9) + 1}.jpg`}
                    />
                  </div>
                  <div className="mt-4 flex justify-between">
                    <div>
                      <h3 className="text-sm text-gray-700">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {product.name}
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                    </div>
                    <p className="text-sm font-medium text-gray-900">{product.price}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white px-4 my-8 py-6 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                Previous
              </button>
              <button className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">1</span> to{" "}
                  <span className="font-medium">8</span> of{" "}
                  <span className="font-medium">{products?.getProducts.length}</span> items
                </p>
              </div>
              <div>
                <nav
                  className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                  aria-label="Pagination"
                >
                  <button className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    aria-current="page"
                    className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                  >
                    1
                  </button>
                  <button className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
                    2
                  </button>
                  <button className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium">
                    3
                  </button>
                  <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                    ...
                  </span>
                  <button className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium">
                    23
                  </button>
                  <button className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
                    24
                  </button>
                  <button className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
                    25
                  </button>
                  <button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <EmptyState />
      )}
    </React.Fragment>
  );
};
