/**
 * Main
 */

export const Home = (): JSX.Element => {
  return (
    <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
      <div className="text-center">
        <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-4xl">
          <span className="block xl:inline">&#8592; Select a Demo Page</span>
        </h2>
      </div>
    </main>
  );
};
