import { GoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../AuthContext";

/**
 * Main
 */

const GOOGLE_CLIENT_ID: string =
  "270449751-4q7k620099pr7sa2gur1tbeb2ftagl14.apps.googleusercontent.com";

export const Logout = (): JSX.Element => {
  const navigate = useNavigate();
  const auth = useAuth();

  const onSuccess = async (): Promise<void> => {
    await auth.signOut();

    navigate("/login", { replace: true });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}>
      <GoogleLogout
        buttonText="Logout"
        clientId={GOOGLE_CLIENT_ID}
        onLogoutSuccess={onSuccess}
        render={(props) => (
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              props.onClick();
            }}
          >
            Logout
          </button>
        )}
      />
    </div>
  );
};
