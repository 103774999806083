import React, { ErrorInfo, ReactNode } from "react";

import { Error } from "./components/Error";

/**
 * Types
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ErrorBoundaryProps {}

interface State {
  readonly hasError: boolean;
}

/**
 * Main
 */

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_error: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(`[!] Error: ${error}\n[!] Info: ${errorInfo}`);
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? <Error /> : children;
  }
}
